body {
  font-family: "Inter", sans-serif;
}
.ant-form-item .ant-form-item-label > label {
  font-size: 0.87rem;
  color: #344054;
}
.ant-form-item .ant-form-item-explain,
.ant-form-item .ant-form-item-extra {
  font-size: 0.87rem;
}
.ant-input {
  padding: 9px 14px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.ant-input.ant-input-sm {
  padding: 4px 8px;
}
.ant-input::placeholder,
.ant-select .ant-select-selection-placeholder {
  color: #667085;
}
.ant-layout {
  background: transparent;
}
.ant-layout main {
  padding: 40px;
}
.ant-layout-sider {
  height: 100vh;
  border-right: 1px solid #eaecf0;
  padding: 30px 20px;
  position: sticky !important;
  top: 0;
}
.ant-menu-inline .ant-menu-item {
  height: 40px;
  line-height: 40px;
}
.ant-layout-sider .sidebar-logo {
  max-width: 75%;
  margin-left: 21px;
  margin-bottom: 30px;
}
.ant-menu {
  font-size: 14px;
}
.ant-menu-light.ant-menu-root.ant-menu-inline,
.ant-menu-light.ant-menu-root.ant-menu-vertical {
  border: none;
}
.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-submenu-title {
  padding-inline: 16px !important;
}
.ant-menu .ant-menu-item .ant-menu-item-icon + span,
.ant-menu .ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu .ant-menu-item .anticon + span,
.ant-menu .ant-menu-submenu-title .anticon + span {
  margin-left: 8px;
}
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 8px 12px;
}
.ant-message-notice {
  text-align: right;
  padding-right: 25px;
  padding-top: 15px;
}
.ant-btn {
  display: inline-flex;
  padding: 10px 18px;
  align-items: center;
  justify-content: center;
}
.ant-table-cell .ant-btn {
  height: auto;
  font-size: 12px;
  padding: 4px 12px;
}
.ant-btn [class^="ri-"],
.ant-btn [class*=" ri-"] {
  display: inline-block;
  margin-right: 3px;
}
.ant-btn.ant-btn-sm {
  font-size: 12px;
  height: 30px;
}
.ant-input-affix-wrapper .ant-input-prefix {
  margin-right: 12px;
  color: #667085;
}
.ant-table-wrapper .ant-table {
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 15px 15px 15px 15px;
  overflow: auto;
}
.ant-row {
  width: 100%;
}
.ant-steps-vertical .ant-steps-item {
  padding-bottom: 50px;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.mt30 {
  margin-top: 30px;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.d-flex {
  display: flex;
}
.align-items-center {
  align-items: center;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.d-none {
  display: none;
}
.d-block {
  display: block;
}
.login-container {
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  /* background-color: #f3f6f9; */
}
.login-card {
  width: 100%;
  margin-top: 50px;
  /* border: 1px solid #e3e6e9; */
  padding: 10px;
  border-radius: 10px;
  /* background-color: #fff; */
}
.sidebar-custom-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
.sidebar-user-card {
  border: 1px solid #d0d5dd;
  border-radius: 8px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebar-user-card .user-details {
  max-width: 110px;
}
.sidebar-user-card .user-details-wrap {
  display: grid;
  grid-template-columns: auto auto;
  gap: 8px;
  align-items: center;
}
.sidebar-user-card .user-details-name {
  font-size: 12px;
  color: #344054;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.sidebar-user-card .user-details-email {
  font-size: 10px;
  color: #667085;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.page-header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}
.page-header-wrapper .page-header-title {
  font-size: 28px;
  font-weight: bold;
}
.page-header-wrapper .page-header-actions {
  display: flex;
  align-items: center;
  gap: 20px;
}
.search-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 350px;
}
.card-checkbox .ant-checkbox-wrapper {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  padding: 15px 28px 15px 10px;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.card-checkbox .ant-checkbox-wrapper .ant-checkbox {
  position: absolute;
  top: 5px;
  right: 5px;
}

.card-checkbox .ant-checkbox-wrapper .ant-checkbox .ant-checkbox-inner,
.card-checkbox .ant-checkbox-checked:after {
  border-radius: 100%;
}
.card-checkbox
  .ant-checkbox-wrapper
  .ant-checkbox:not(.ant-checkbox-checked)
  .ant-checkbox-inner {
  border-color: transparent;
}
.card-checkbox .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  background-color: #e6f4ff;
  border-color: #1677ff;
  border-width: 2px;
  padding: 14px 27px 14px 9px;
  /* border-width: 2px; */
}
.report_type_checkboxes .ant-checkbox-wrapper {
  padding: 15px 28px 15px 28px;
}
.report_type_checkboxes .ant-checkbox + span {
  width: 100%;
  text-align: center;
}
.report_type_checkboxes .ant-checkbox-wrapper.ant-checkbox-wrapper-checked {
  padding: 14px 27px 14px 27px;
}
.marketplace-region {
  color: #8996a9;
}
.antd-table-filter-dropdown {
  padding: 15px 10px;
  min-width: 200px;
  display: flex;
  justify-content: center;
}
.appplied-filter-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
}
.applied-filters-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  flex-grow: 1;
}
.applied-filter-wrap {
  padding: 15px;
  background-color: #e6f4ff;
  color: #1677ff;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
}
.applied-filter-wrap > span {
  cursor: pointer;
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.applied-filter {
  font-weight: bold;
}
.applied-filter > span {
  font-size: 10px;
  font-weight: normal;
  margin-right: 10px;
}
.applied-filter-wrap .applied-filter-remove {
  display: flex;
  align-items: center;
  margin-left: 5px;
  cursor: pointer;
}
.detail-block {
  line-break: anywhere;
}
.detail-block > label,
.detail-block > span {
  display: block;
}
.detail-block > label {
  font-size: 14px;
  color: #667085;
  margin-bottom: 3px;
}
.detail-block > span {
  font-size: 18px;
  font-weight: bold;
  display: inline-block;
}
.marketplace-detail-card,
.report-type-wrapper {
  border-radius: 8px;
  border: 1px solid #d0d5dd;
  padding: 15px 28px 15px 10px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 3px;
  align-items: center;
  position: relative;
  font-weight: normal;
  text-align: center;
}
.report-type-wrapper {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 18px 20px;
  width: 100%;
  white-space: nowrap;
}
.table-tag .ant-alert {
  display: inline-flex;
  padding: 4px 8px;
}
.table-tag .ant-alert .ant-alert-content {
  font-size: 12px;
}
.product-image-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.product-image-wrapper {
  width: 102px;
  height: 102px;
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}
.product-image-wrapper-sm {
  width: 50px;
  height: 50px;
  padding: 5px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
}
.product-title {
  font-size: 16px;
}
.product-price {
  color: #1677ff;
}
.detail-block > label {
  /* font-weight: 600; */
}
.detail-block ul {
  padding-inline-start: 16px;
  list-style-type: decimal;
}
.detail-block ul li {
  font-size: 18px;
  font-weight: bold;
}
.pd-identifier {
  color: #667085;
  margin-bottom: 10px;
  display: block;
}
.pd-product-name {
  font-weight: bold;
  font-size: 20px;
}
.ant-descriptions-item-content ul {
  padding-left: 18px;
}
.pd-status-wrap {
  display: inline-block;
  margin-bottom: 20px;
}
.amazon-serach-modal-search
  .ant-input-group
  .ant-input-group-addon
  .ant-select {
  margin: 0;
  border: 1px solid #d9d9d9;
  border-radius: 8px 0 0 8px;
  border-right: 0px;
}
.amazon-serach-modal-search .ant-input-group .ant-input-group-addon {
  padding: 0;
  border: 0;
}
.amazon-serach-modal-search .ant-input-group .ant-input {
  height: 46px;
}
.amazon-serach-modal-search .ant-input-group .ant-input-group-addon .ant-btn {
  border-radius: 0 8px 8px 0;
  border-left: 0;
  height: 46px;
}
.read-more-btn {
  background-color: transparent;
  border: none;
  color: #8996a9;
  margin: 0;
  padding: 0;
  font-size: 14px;
}
@media all and (min-width: 576px) {
  .login-card {
    width: 480px;
    padding: 30px;
  }
}
.ant-checkbox-wrapper {
  align-items: center;
}
.ant-form-item-required::before {
  order: 1;
  margin-right: 0;
  margin-left: 4px;
}
.ant-list-bordered {
  overflow: hidden;
}

.prdouct-info-btn {
  display: flex;
  justify-content: end;
}
